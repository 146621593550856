export default {
    button: {
        save: "保存",
        submit: "提交",
        confirm: "确认",
        select: "选择",
        cancel: "取消",
        add: "添加",
        remove: "删除",
        edit: "编辑",
    },
    tip: {
        submit_success: "提交成功",
        save_success: "保存成功",
        operate_success: "操作成功",
    },
};

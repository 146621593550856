export default {
    default_title: "产品选择",

    search_tip: "请输入产品名称或编码",
    category: "产品分类",
    count_prefix: "已选择 ",
    count_suffix: " 个零配件",

    table: {
        goods: "产品/物料",
        sn: "编码",
        version: "版本",
        quality: "数量",
        unit_price: "单价",
        op: "操作",
    },
};

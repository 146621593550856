import { i18n } from "@/locale";
const { t: $t } = i18n.global;

export default [
    {
        path: "/oem",
        name: "oem__root__",
        redirect: {
            name: "oem-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "oem",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "oem-index",
                component: () => import("@/views/oem/index.vue"),
                meta: {
                    title: $t("nav.control"),
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/oem/order",
        name: "oem-order",
        redirect: "/oem/order/mine",
        component: () => import("@/layouts/default.vue"),
        meta: {
            title: $t("nav.oem.order_management.name"),
            parent: "oem",
            icon: "order",
            permission: "manage_oem",
        },
        children: [
            {
                path: "create",
                name: "oem-order-create",
                component: () => import("@/views/oem/create.vue"),
                meta: {
                    title: $t("nav.oem.order_management.new"),
                    icon: "preset",
                },
            },
            {
                path: ":id(\\d+)/edit",
                name: "oem-order-edit",
                component: () => import("@/views/oem/edit.vue"),
                hidden: true,
                meta: {
                    title: $t("nav.oem.order_management.edit"),
                    icon: "preset",
                    activeMenu: "/oem/order/mine",
                },
            },
            {
                path: ":id(\\d+)",
                name: "oem-order-detail",
                component: () => import("@/views/oem/detail.vue"),
                hidden: true,
                meta: {
                    title: $t("nav.oem.order_management.detail"),
                    icon: "preset",
                    activeMenu: "/oem/order/mine",
                },
            },
            {
                path: "mine",
                name: "oem-order-mine",
                component: () => import("@/views/oem/mine.vue"),
                meta: {
                    title: $t("nav.oem.order_management.mine"),
                    icon: "oem",
                },
            },
        ],
    },
];

import { useCount } from "@/utils/count";

const load = async () => {
    await useCount().countBill();
    await useCount().countOrderConfirm();
    await useCount().countPendingWorkflow();
    await useCount().countMyIntentions();
};

export default [
    {
        path: "/fms",
        name: "fms__root__",
        redirect: {
            name: "fms-index",
        },
        component: () => import("@/layouts/default.vue"),
        beforeEnter: async (to, from, next) => {
            await load();
            next();
        },
        meta: {
            parent: "fms",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "fms-index",
                component: () => import("@/views/fms/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/fms/customer",
        name: "fms-customer",
        meta: {
            title: "客户管理",
            icon: "enterprise",
            parent: "fms",
            permission: "manage_customer",
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/customer/organization",
        children: [
            {
                path: "organization",
                name: "omp-enterprise-organization",
                component: () => import("@/views/omp/enterprise/organization.vue"),
                meta: {
                    title: "企业管理",
                    icon: "organization",
                },
            },
            {
                path: "dashboard",
                name: "omp-enterprise-dashboard",
                component: () => import("@/views/omp/enterprise/dashboard.vue"),
                meta: {
                    title: "园区管理",
                    icon: "dashboard_mng",
                },
            },
            {
                path: "user",
                name: "omp-account-user",
                component: () => import("@/views/omp/account/user.vue"),
                meta: {
                    title: "账号管理",
                    icon: "users",
                },
            },
            {
                path: "address",
                name: "fms-customer-address",
                component: () => import("@/views/fms/customer/address.vue"),
                meta: {
                    title: "地址管理",
                    icon: "address-mng",
                },
            },
        ],
    },
    {
        path: "/fms/order",
        name: "fms-order",
        meta: {
            title: "市场订单",
            icon: "invoice",
            parent: "fms",
            permission: "manage_order",
        },
        beforeEnter: async (to, from, next) => {
            await load();
            next();
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/order/intention",
        children: [
            {
                path: "intention",
                name: "fms-order-intention",
                component: () => import("@/views/fms/order/intention"),
                meta: {
                    title: "意向订单",
                    icon: "intention",
                    affix: false,
                    keepAlive: true,
                },
            },
            {
                path: "intention_edit/:id(\\d+)?",
                name: "fms-order-intention-edit",
                hidden: true,
                component: () => import("@/views/fms/order/components/intention-edit.vue"),
                meta: {
                    title: "订单编辑",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/order/intention",
                },
            },
            {
                path: "intention_detail/:id(\\d+)?",
                name: "fms-order-intention-detail",
                hidden: true,
                component: () => import("@/views/fms/order/components/intention-detail.vue"),
                meta: {
                    title: "订单详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/order/intention",
                },
            },
            {
                path: "intention_sign/:id(\\d+)?",
                name: "fms-order-intention-sign",
                hidden: true,
                component: () => import("@/views/fms/order/components/intention-sign.vue"),
                meta: {
                    title: "开始签约",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/order/intention",
                },
            },
            {
                path: "sign",
                name: "fms-order-sign",
                component: () => import("@/views/fms/order/sign"),
                meta: {
                    title: "签约订单",
                    icon: "sign",
                    affix: false,
                },
            },
            {
                path: "oem",
                name: "fms-order-oem",
                component: () => import("@/views/fms/order/oem"),
                meta: {
                    title: "OEM订单",
                    icon: "oem",
                    affix: false,
                },
            },
            {
                path: "oem/detail/:id(\\d+)?",
                name: "fms-order-oem-detail",
                hidden: true,
                component: () => import("@/views/fms/order/components/oem-detail.vue"),
                meta: {
                    title: "订单详情",
                    icon: "oem",
                    affix: false,
                    activeMenu: "/fms/order/oem",
                },
            },
            {
                path: "sign_create",
                name: "entry-sign-add",
                component: () => import("@/views/fms/order/components/entry-order.vue"),
                meta: {
                    title: "录入订单",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "sign_edit/:id(\\d+)",
                name: "entry-sign-edit",
                component: () => import("@/views/fms/order/components/entry-order.vue"),
                meta: {
                    title: "编辑订单",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "sign_detail/:id(\\d+)?",
                name: "fms-order-sign-detail",
                hidden: true,
                component: () => import("@/views/fms/order/components/sign-detail.vue"),
                meta: {
                    title: "签约详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/order/sign",
                },
            },
            {
                path: "start_produce/:order_id(\\d+)",
                name: "fms-order-start-produce",
                component: () => import("@/views/fms/order/components/start-produce.vue"),
                meta: {
                    title: "开始生产",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "contact",
                name: "contact",
                component: () => import("@/views/cms/service/contact"),
                meta: {
                    title: "联系咨询",
                    icon: "phone-call",
                    affix: false,
                },
            },
            {
                path: "quotation",
                name: "quotation",
                component: () => import("@/views/cms/service/quotation"),
                meta: {
                    title: "报价咨询",
                    icon: "discount",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/bill",
        name: "fms-bill",
        meta: {
            title: "财务账单",
            icon: "order",
            parent: "fms",
            permission: "manage_bill",
        },
        beforeEnter: async (to, from, next) => {
            await load();
            next();
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/bill/order-collection",
        children: [
            {
                path: "order-collection",
                name: "fms-bill-order-collection",
                component: () => import("@/views/fms/bill/order-collection.vue"),
                meta: {
                    title: "合同收款",
                    icon: "order-collection",
                    affix: false,
                },
            },
            {
                path: "collection-detail/:id(\\d+)?",
                name: "fms-bill-collection-detail",
                hidden: true,
                component: () => import("@/views/fms/bill/collection-detail.vue"),
                meta: {
                    title: "收款详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/bill/order-collection",
                },
            },
            {
                path: "statement",
                name: "fms-bill-statement",
                component: () => import("@/views/fms/bill/statement.vue"),
                meta: {
                    title: "服务收款",
                    icon: "order-collection",
                    affix: false,
                },
            },
            {
                path: "oem-detail/:id(\\d+)?",
                name: "fms-bill-oem-detail",
                hidden: true,
                component: () => import("@/views/fms/bill/oem-detail.vue"),
                meta: {
                    title: "OEM收款详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/bill/oem",
                },
            },
            {
                path: "oem",
                name: "fms-bill-oem",
                component: () => import("@/views/fms/bill/oem.vue"),
                meta: {
                    title: "OEM收款",
                    icon: "order-collection",
                    affix: false,
                },
            },
            {
                path: "invoice",
                name: "fms-bill-invoice",
                component: () => import("@/views/fms/bill/invoice.vue"),
                meta: {
                    title: "发票中心",
                    icon: "invoice",
                    affix: false,
                },
            },
            {
                path: "goods_price",
                name: "fms-mall-goods-price",
                component: () => import("@/views/fms/mall/goods_price.vue"),
                meta: {
                    title: "产品定价",
                    icon: "discount",
                    affix: false,
                },
            },
            {
                path: "goods_price_detail/:id(\\d+)?",
                name: "fms-mall-goods-price-detail",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_price_detail.vue"),
                meta: {
                    title: "定价详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/mall/goods_price",
                },
            },
            {
                path: "handmade_goods",
                name: "fms-mall-handmade-goods",
                component: () => import("@/views/fms/mall/handmade_goods.vue"),
                meta: {
                    title: "服务定价",
                    icon: "discount",
                    affix: false,
                },
            },
            // {
            //     path: "business",
            //     name: "fms-order-business",
            //     component: () => import("@/views/fms/order/business.vue"),
            //     meta: {
            //         title: "服务订单",
            //         icon: "enterprise",
            //         affix: false,
            //     },
            // },
        ],
    },
    {
        path: "/fms/mall",
        name: "fms-mall",
        meta: {
            title: "产品研发",
            icon: "cpu",
            parent: "fms",
            permission: "manage_production",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/mall/goods",
        children: [
            {
                path: "goods",
                name: "fms-mall-goods",
                component: () => import("@/views/fms/mall/goods.vue"),
                meta: {
                    title: "产品管理",
                    icon: "goods",
                    affix: false,
                    keepAlive: true,
                },
            },
            {
                path: "good/:id(\\d+)?",
                name: "fms-mall-goods-form",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_form.vue"),
                meta: {
                    title: "产品编辑",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/mall/goods",
                },
            },
            {
                path: "good_detail/:id(\\d+)?",
                name: "fms-mall-goods-detail",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_detail.vue"),
                meta: {
                    title: "产品详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/mall/goods",
                },
            },
            {
                path: "category",
                name: "fms-mall-category",
                component: () => import("@/views/fms/mall/goods_category.vue"),
                meta: {
                    title: "类型配置",
                    icon: "setting",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/produce",
        name: "fms-produce",
        meta: {
            title: "生产管理",
            icon: "product",
            parent: "fms",
            permission: "manage_produce",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/produce/task",
        children: [
            {
                path: "task",
                name: "fms-produce-task",
                component: () => import("@/views/fms/produce/task/index.vue"),
                meta: {
                    title: "生产任务",
                    icon: "workflows",
                    affix: false,
                },
            },
            {
                path: "task-edit/:id(\\d+)?",
                name: "fms-produce-task-edit",
                component: () => import("@/views/fms/produce/task/form.vue"),
                meta: {
                    title: "编辑生产任务",
                    activeMenu: "/fms/produce/task",
                },
                hidden: true,
            },
            {
                path: "task-detail/:id(\\d+)",
                name: "fms-produce-task-detail",
                component: () => import("@/views/fms/produce/task/detail.vue"),
                meta: {
                    title: "生产任务详情",
                    activeMenu: "/fms/produce/task",
                },
                hidden: true,
            },
            {
                path: "inventory",
                name: "fms-produce-inventory",
                component: () => import("@/views/fms/produce/inventory/index.vue"),
                meta: {
                    title: "库存管理",
                    icon: "raw-material",
                    affix: false,
                },
            },
            {
                path: "inventory_detail/:id(\\d+)?",
                name: "fms-mall-inventory-detail",
                hidden: true,
                component: () => import("@/views/fms/produce/inventory/detail.vue"),
                meta: {
                    title: "库存详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/produce/inventory",
                },
            },
            {
                path: "stock-record",
                name: "fms-mall-stock-record",
                component: () => import("@/views/fms/mall/stock_record.vue"),
                meta: {
                    title: "流转记录",
                    icon: "exchange",
                    affix: false,
                },
            },
            {
                path: "plan",
                name: "fms-produce-plan",
                component: () => import("@/views/fms/produce/plan/index.vue"),
                meta: {
                    title: "生产规划",
                    icon: "plan",
                    affix: false,
                },
            },
            {
                path: "stat",
                name: "fms-produce-stat",
                component: () => import("@/views/fms/produce/stat/index.vue"),
                meta: {
                    title: "统计分析",
                    icon: "workflows-stat",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/after-sales",
        name: "fms-after-sales",
        meta: {
            title: "售后中心",
            icon: "chatbot",
            parent: "fms",
            permission: "manage_after_sale",
        },
        beforeEnter: async (to, from, next) => {
            await load();
            next();
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/after-sales/workflows",
        children: [
            {
                path: "workflows",
                name: "fms-workflows",
                component: () => import("@/views/fms/after-sales/workflows.vue"),
                meta: {
                    title: "售后工单",
                    icon: "workflows",
                    affix: false,
                },
            },
            // {
            //     path: "workflows-mine",
            //     name: "fms-workflows-mine",
            //     component: () => import("@/views/fms/after-sales/workflows-mine.vue"),
            //     meta: {
            //         title: "我的工单",
            //         icon: "my-workflows",
            //         affix: false,
            //     },
            // },
            {
                path: "create",
                name: "create-workflow",
                component: () => import("@/views/fms/after-sales/components/workflow-form.vue"),
                meta: {
                    title: "创建工单",
                    activeMenu: "/fms/after-sales/workflows",
                },
                hidden: true,
            },
            {
                path: "edit/:id(\\d+)",
                name: "edit-workflow",
                component: () => import("@/views/fms/after-sales/components/workflow-form.vue"),
                meta: {
                    title: "编辑工单",
                    activeMenu: "/fms/after-sales/workflows",
                },
                hidden: true,
            },
            {
                path: "detail/:id(\\d+)",
                name: "workflow-detail",
                component: () => import("@/views/fms/after-sales/components/workflow-detail.vue"),
                meta: {
                    title: "工单详情",
                    activeMenu: "/fms/after-sales/workflows",
                },
                hidden: true,
            },
            {
                path: "analysis",
                name: "fms-after-sales-analysis",
                component: () => import("@/views/fms/after-sales/workflows-stat.vue"),
                meta: {
                    title: "统计分析",
                    icon: "workflows-stat",
                    affix: false,
                },
            },
            {
                path: "types",
                name: "fms-after-sales-types",
                component: () => import("@/views/fms/after-sales/types.vue"),
                meta: {
                    title: "类型配置",
                    icon: "setting",
                    affix: false,
                    permission: "fms_manage_after_sales",
                },
            },
            {
                path: "template",
                name: "fms-after-sales-template",
                component: () => import("@/views/fms/after-sales/workflows-template.vue"),
                meta: {
                    title: "处理模板",
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "ewx-phone",
                name: "enterprise-wechat-phone",
                component: () => import("@/views/fms/after-sales/ewx-phone.vue"),
                meta: {
                    title: "企微电话",
                    icon: "wechat-phone",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/repair",
        name: "repair",
        meta: {
            title: "维修中心",
            icon: "maintenance",
            parent: "fms",
            permission: "manage_repair",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/repair/task",
        children: [
            {
                path: "task",
                name: "fms-repair-task",
                component: () => import("@/views/fms/repair/workflows.vue"),
                meta: {
                    title: "维修工单",
                    icon: "workflows",
                    affix: false,
                },
            },
            {
                path: "create",
                name: "fms-repair-task-create",
                component: () => import("@/views/fms/repair/components/workflow-form.vue"),
                meta: {
                    title: "创建维修任务",
                    activeMenu: "/fms/repair/task",
                },
                hidden: true,
            },
            {
                path: "edit/:id(\\d+)",
                name: "fms-repair-task-edit",
                component: () => import("@/views/fms/repair/components/workflow-form.vue"),
                meta: {
                    title: "编辑维修任务",
                    activeMenu: "/fms/repair/task",
                },
                hidden: true,
            },
            {
                path: "detail/:id(\\d+)",
                name: "fms-repair-task-detail",
                component: () => import("@/views/fms/repair/components/workflow-detail.vue"),
                meta: {
                    title: "维修任务详情",
                    activeMenu: "/fms/repair/task",
                },
                hidden: true,
            },
            {
                path: "analysis",
                name: "fms-repair-analysis",
                component: () => import("@/views/fms/repair/workflows-stat.vue"),
                meta: {
                    title: "统计分析",
                    icon: "workflows-stat",
                    affix: false,
                },
            },
            {
                path: "types",
                name: "fms-repair-types",
                component: () => import("@/views/fms/repair/types.vue"),
                meta: {
                    title: "类型配置",
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "template",
                name: "fms-repair-template",
                component: () => import("@/views/fms/repair/workflows-template.vue"),
                meta: {
                    title: "处理模板",
                    icon: "setting",
                    affix: false,
                },
            },
        ],
    },
];

export default {
    default_title: "Product Select",

    search_tip: "Please enter the product name keyword or code",
    category: "Product Category",
    count_prefix: "Selected ",
    count_suffix: " goods",

    table: {
        goods: "Product/Material",
        sn: "SN Code",
        version: "Version",
        quality: "Quantity",
        unit_price: "Unit Price",
        op: "Operation",
    },
};
